import React from 'react';

import { ParticipantStep } from '@wix/ambassador-challenges-v1-participant/types';

import { ButtonNames } from '../../../../../contexts/main/biInterfaces';

import { Spinner } from '../../../../../components-shared/Spinner';

import { StepsListAsTiles } from '../../components/StepsListAsTiles';

import { IChallengePageProps, IChallengePageState } from '../../interfaces';
import {
  InjectedBiLoggerProps,
  IWixSDKContext,
  withBi,
  withExperiments,
} from '@wix/yoshi-flow-editor';

import { ShareButton } from 'wix-ui-tpa/cssVars';
import { LayoutSelectedTab } from '../../../Settings/tabs/Design/components/OverviewScheduleSwitcher/constants';

import { SectionsListAsTiles } from '../../components/SectionsListAsTiles';
import { ChallengeOverview } from '../../components/ChallengeOverview';
import { StepViewBreadcrumbs } from '../../components/StepViewBreadcrumbs';
import { ToastType } from '../../../../../contexts/ToastContext/interfaces';
import {
  ChallengeNotification,
  IChallengeNotificationType,
} from '../../components/ChallengeNotification';

import { classes, st } from './SidebarLayoutForParticipant.st.css';
import '../../../../../components-shared/Ricos/rce/rce.global.scss';
import utils, {
  ChallengeEntityType,
  getFirstAvailableStep,
  getFlatStepsList,
  getNextStepToComplete,
  getProgramProgress,
  INextEntity,
  isParticipantCompletedChallenge,
  isStepResolved,
} from '../utils';
import {
  getWixAdvertisementBannerHeight,
  setElementToViewport,
} from '../../../../../services/scrollHelpers';
import { SideBarStepView } from '../../components/SideBarStepView';
import { OverviewSidebar } from '../../components/OverviewSidebar';
import { ChallengeSummary } from '../../components/ChallengeSummary/ChallengeSummary';
import {
  FinishedNotification,
  SectionControls,
  StepControls,
} from '../../components/StepControls';
import { ShareModal } from '../../components/ShareModal';
import { SidebarSectionView } from '../../components/SidebarSectionView';
import {
  getFirstAvailableStepFromSection,
  getFirstSection,
} from '../../../../../selectors/sections';
import { SidebarControlsContainer } from '../../components/SidebarControlsContainer/SidebarControlsContainer';
import { SidebarLayoutButton } from '../../components/Buttons/SidebarLayoutButton/SidebarLayoutButton';
import { memberWebAppButtonClick as memberWebAppButtonClickV2 } from '@wix/bi-logger-challenges-member-web/v2';
import { withGeneralData } from '../../../../../contexts/GeneralDataProvider/withGeneralData';
import { withToast } from '../../../../../contexts/ToastContext/withToast';

import loadable from '@wix/yoshi-flow-editor/loadable';
import { ISidebarMobileScreenFromSettings } from '../../../Settings.mobile/tabs/Design/DesignSettings';
import { ProgramRejoin } from '../../components/ProgramRejoin';
import { FormQuiz, IFormHandleExtended } from '../../components/FormQuiz';
import { FormQuizControls } from '../../components/FormQuizControls';
import { SidebarLayoutBaseContext } from './contexts/SidebarLayoutBase/SidebarLayoutBaseContext';
import { sidebarLayoutDataHooks } from './sidebarLayoutDataHooks';
import {
  ILeaveChallengeModalContext,
  withLeaveChallengeModal,
} from '../../../../../contexts/LeaveChallengeModal/LeaveChallengeModalContext';
import { LeaveModalType } from '../../../../../contexts/LeaveChallengeModal/LeaveChallengeModalProvider';
import { isSelfPaced } from '../../../../../selectors/isSelfPaced';

const ChallengeQuestionnaire = loadable(() => {
  return import(
    /* webpackChunkName: "ProgramsQuiz"*/ '../../components/ChallengeQuestionnaire/ChallengeQuestionnaire'
  );
});

export interface IExtendWidget {
  isEditor: boolean;
  isMobile: boolean;
  lng: string;
  isFullscreen: boolean;
  experiments: any;
}

type Props = IChallengePageProps &
  IWixSDKContext &
  IExtendWidget &
  InjectedBiLoggerProps &
  ILeaveChallengeModalContext;

class SidebarComponent extends React.Component<Props, IChallengePageState> {
  static displayName = 'ChallengePage';
  public readonly pageName = 'challenge-page';

  private readonly wrapperRef = null;
  private readonly stepViewRef = null;
  private readonly formQuizRef: React.RefObject<IFormHandleExtended> = null;

  constructor(props: Props) {
    super(props);

    const { participant } = this.props;
    let { chosenSectionTileId, currentStep } = this.getDeepLinkInitialState();

    if (
      !currentStep &&
      !chosenSectionTileId &&
      !this.props.settings.overviewTabDisplay
    ) {
      const flatStepsList = getFlatStepsList(props);
      if (getProgramProgress(flatStepsList).isProgramNotStarted) {
        const firstSection = getFirstSection(props.listParticipantSections);
        if (firstSection) {
          chosenSectionTileId = firstSection.id;
        } else {
          currentStep = getFirstAvailableStep(flatStepsList);
        }
      } else {
        currentStep = getFirstAvailableStep(flatStepsList);
      }
    }

    this.state = {
      wixAdvertisementBannerHeight: undefined,
      currentStep,
      chosenSectionTileId,
      isShareModalOpened: false,
      isFeedbackModalOpened: false,
      selectedPaymentOption: null,
      isParticipantCompletedChallengeState:
        isParticipantCompletedChallenge(participant),
      sharedCurrentDateForWeeksSelector: null,
      mobileScreen: 'list',
      isCurrentStateWasResolved: false,
      savedQuestionnaireData: null,
    };

    this.wrapperRef = React.createRef();
    this.stepViewRef = React.createRef();
    this.formQuizRef = React.createRef<IFormHandleExtended>();
  }

  private getDeepLinkInitialState() {
    let chosenSectionTileId = null;
    let currentStep = null;
    if (this.props.selectedSection) {
      chosenSectionTileId = this.props.selectedSection.id;
    }
    if (this.props.selectedStep) {
      currentStep = this.props.selectedStep;
    }
    return { chosenSectionTileId, currentStep };
  }

  static getDerivedStateFromProps = (
    props: IChallengePageProps & { isEditor: boolean },
  ): Partial<IChallengePageState> => {
    const result: any = {};

    if (props.isEditor) {
      result.currentStep = props?.participantSteps?.steps?.[0];

      if (
        props.sidebarLayoutMobileScreen ===
        ISidebarMobileScreenFromSettings.List
      ) {
        result.mobileScreen = 'list';
      } else if (
        props.sidebarLayoutMobileScreen ===
          ISidebarMobileScreenFromSettings.Overview ||
        props.sidebarLayoutMobileScreen ===
          ISidebarMobileScreenFromSettings.Schedule
      ) {
        result.mobileScreen = 'view';
      }
    }

    return result;
  };

  async componentDidMount() {
    this.setState({
      wixAdvertisementBannerHeight: getWixAdvertisementBannerHeight(),
    });

    if (this.props.selectedSection) {
      this.onSectionChosen(this.props.selectedSection.id);
    }
    if (this.props.selectedStep) {
      this.onStepChosen(this.props.selectedStep);
    }
    if (this.props.isDescriptionShown) {
      this.onStepChosen(null);
    }
  }

  componentDidUpdate(prevProps): void {
    const {
      t,
      showToast,
      listParticipantSections,
      challengeData: { challenge },
      participantSteps: { steps = [] } = {},
      updateParticipantStepStatus,
    } = this.props;

    const isSections =
      isSelfPaced(challenge) && this.props.listParticipantSections?.length;

    if (
      prevProps.isResolveStepRequestInProgress &&
      !this.props.isResolveStepRequestInProgress
    ) {
      const step = this.props.resolveStepData;
      const error = this.props.resolveStepError;

      if (!error) {
        updateParticipantStepStatus({
          replace: true,
          stepId: step?.id,
          transitions: step?.transitions,
          quizSubmission: step?.quizSubmission,
          steps,
          sections: isSections ? listParticipantSections : [],
        });

        this.setState({
          currentStep: {
            ...this.state.currentStep,
            ...step,
          },
        });
      } else {
        showToast(t('toast.step-completed-error'), {
          type: ToastType.error,
        });
      }
    }
  }

  sendBiButtonClick(buttonName: ButtonNames, step?: string) {
    void this.props.bi.report(
      memberWebAppButtonClickV2({
        buttonName,
        step,
      }),
    );
  }

  setStep(currentStep: ParticipantStep, preventScrolling?: boolean) {
    this.setState(
      {
        currentStep,
      },
      () => {
        if (!preventScrolling) {
          document
            .querySelector(`li[data-id="${currentStep?.id}"]`)
            ?.scrollIntoView({
              behavior: 'smooth',
            });
        }
      },
    );
  }

  onSectionChosen = (chosenSectionTileId: string) => {
    if (this.props.isResolveStepRequestInProgress) {
      return;
    }

    this.scrollToContent();
    const goToSection = () => {
      this.setState({
        currentStep: null,
        mobileScreen: 'view',
        chosenSectionTileId,
      });
    };
    if (this.formQuizRef?.current?.dirty) {
      this.props.showModal(
        {
          participantId: this.props.participant.id,
          modalType: LeaveModalType.LeaveSession,
        },
        undefined,
        goToSection,
      );
      return;
    }
    goToSection();
  };

  onStepChosen = (step: ParticipantStep): void => {
    if (this.props.isResolveStepRequestInProgress) {
      return;
    }

    this.scrollToContent();
    this.sendBiButtonClick(
      step ? ButtonNames.StepTileSelection : ButtonNames.OverviewTileSelection,
      step?.id,
    );

    const goToNextStep = () => {
      this.setState({
        currentStep: step,
        mobileScreen: 'view',
        chosenSectionTileId: null,
      });
    };
    if (this.formQuizRef?.current?.dirty) {
      this.props.showModal(
        {
          participantId: this.props.participant.id,
          modalType: LeaveModalType.LeaveSession,
        },
        undefined,
        goToNextStep,
      );
      return;
    }
    goToNextStep();
  };

  async onNextEntity(nextEntity: INextEntity): Promise<void> {
    switch (nextEntity.type) {
      case ChallengeEntityType.STEP:
        this.onStepChosen(nextEntity.step);
        this.sendBiButtonClick(ButtonNames.NextStep);
        break;
      case ChallengeEntityType.SECTION:
        this.onSectionChosen(nextEntity.section?.id);
        this.sendBiButtonClick(ButtonNames.NextSection);
        break;
      default:
        return null;
    }
  }

  private scrollToContent() {
    const { isMobile } = this.props;

    setElementToViewport(this.stepViewRef.current);
    if (isMobile) {
      window?.scrollTo?.(0, 0);
    }
  }

  onFeedbackView = async (step: ParticipantStep) => {
    this.setState(
      {
        currentStep: step,
        isFeedbackModalOpened: true,
        savedQuestionnaireData: null,
      },
      () => {
        this.sendBiButtonClick(ButtonNames.SeeFeedback);
      },
    );
  };

  onStepResolve = async (step: ParticipantStep) => {
    this.setState(
      {
        currentStep: step,
        savedQuestionnaireData: null,
      },
      async () => {
        this.sendBiButtonClick(ButtonNames.StepComplete);

        if (utils.isFeedbackFormRequired(step)) {
          this.setState({
            isFeedbackModalOpened: true,
          });
        } else {
          void this.resolveStep();
        }
      },
    );
  };

  async resolveStep() {
    const {
      listParticipantSections,
      challengeData: { challenge },
      participantSteps: { steps = [] } = {},
      updateParticipantStepStatus,
      resolveStep,
    } = this.props;

    const isSections =
      isSelfPaced(challenge) && this.props.listParticipantSections?.length;

    this.setState(
      {
        isFeedbackModalOpened: false,
      },
      async () => {
        const stepForView = this.state.currentStep;

        if (stepForView?.id) {
          const {
            isStepResolved: isCurrentStepResolved,
            currentStepAfterUpdate,
          } = utils.getResolveStepData(
            stepForView,
            this.state.savedQuestionnaireData,
            null,
          );

          this.setState(
            {
              currentStep: currentStepAfterUpdate,
              isCurrentStateWasResolved: isStepResolved(stepForView),
              savedQuestionnaireData: null,
            },
            async () => {
              /*
                Optimistic Update.
                Change the status of current step and then run resolve request.
                Otherwise the current step can be changed faster then request finished.
               */
              updateParticipantStepStatus({
                stepId: currentStepAfterUpdate?.id,
                transitions: currentStepAfterUpdate?.transitions,
                feedbackItems: currentStepAfterUpdate?.feedback,
                steps,
                sections: isSections ? listParticipantSections : [],
              });
              await resolveStep({
                stepId: stepForView.id,
                feedback: currentStepAfterUpdate?.feedback,
                successMessage: {
                  key: isCurrentStepResolved
                    ? 'toast.step-feedback-updated'
                    : 'toast.step-completed',
                  type: ToastType.success,
                },
                isUpdate: isCurrentStepResolved,
              });
            },
          );
        } else {
          console.error("Can't find step for resolve.");
        }
      },
    );
  }

  async resolveQuiz(quizSubmissionId?: string) {
    const { resolveStep } = this.props;
    const stepForView = this.state.currentStep;

    if (stepForView?.id) {
      await resolveStep({
        stepId: stepForView.id,
        successMessage: {
          type: ToastType.success,
          key: 'toast.quiz-completed',
        },
        unresolvedMessage: {
          type: ToastType.error,
          key: 'toast.quiz-completed-error',
        },
        quizSubmission: {
          quizSubmissionId,
          score: 100, // for the future
        },
      });
    } else {
      console.error("Can't find step for resolve.");
    }
  }

  getIsStartDisabled() {
    const { challenge } = this.props.challengeData;
    const isSPC = isSelfPaced(challenge);
    const isSections = isSPC && this.props.listParticipantSections?.length;

    if (isSections) {
      const nextStep = getFirstAvailableStepFromSection(
        this.props.listParticipantSections || [],
      );
      const nextSection = getFirstSection(this.props.listParticipantSections);
      return !nextStep && !nextSection;
    }

    return false;
  }

  goToMobileList = () => {
    const { isMobile } = this.props;
    if (isMobile) {
      this.setState(
        {
          mobileScreen: 'list',
        },
        () => {
          // window?.scrollTo?.(0, 0);
        },
      );
    }
  };

  getQuizData = () => {
    const step = this.state.currentStep;
    const formId = step?.source?.settings?.general?.quiz?.quizId;
    const isQuiz = !!formId;
    const quizSubmissionId = step?.quizSubmission?.quizSubmissionId;
    const isResolved = isStepResolved(this.state.currentStep);

    return {
      isQuiz,
      formId,
      quizSubmissionId,
      isResolved,
    };
  };

  render() {
    const { challengeData, isLoading, isFullWidthLayout, settings, isMobile } =
      this.props;
    const cssPBEnabled = this.props.experiments.enabled(
      'specs.programs.OOIStyleBP',
    );

    const { mobileScreen } = this.state;
    // eslint-disable-next-line no-nested-ternary
    return challengeData && challengeData.challenge ? (
      isLoading ? (
        <div
          className={classes.fullPageSpinner}
          data-hook={sidebarLayoutDataHooks.spinner()}
        >
          <Spinner />
        </div>
      ) : (
        <main
          data-hook={sidebarLayoutDataHooks.main()}
          className={st(classes.root, {
            mobile: isMobile,
            mobileScreen,
            newScrolling: true,
            fullWidthLayout: isFullWidthLayout,
            withoutSpace:
              !cssPBEnabled && parseInt(settings.sidebarLayoutSpace, 10) === 0,
            align: settings.sidebarLayoutTextAlignment,
            fullscreen: this.props.isFullscreen,
          })}
        >
          {this.renderWithChallenge()}
        </main>
      )
    ) : null;
  }

  private openFirstSectionIfAvailable() {
    const firstSectionId = getFirstSection(
      this.props.listParticipantSections,
    )?.id;
    if (firstSectionId) {
      this.onSectionChosen(firstSectionId);
      return true;
    }
  }

  renderWithChallenge() {
    const stickyPositionTop = this.state.wixAdvertisementBannerHeight
      ? `${this.state.wixAdvertisementBannerHeight}px`
      : undefined;
    const cssPBEnabled = this.props.experiments.enabled(
      'specs.programs.OOIStyleBP',
    );

    return (
      <>
        <ChallengeNotification // no notifications now, saved for future cases
          challengeJoinRestrictions={[]}
          type={IChallengeNotificationType.Sidebar}
        />
        <SidebarLayoutBaseContext.Provider
          value={{
            formQuizRef: this.formQuizRef,
            currentStep: this.state.currentStep,
            isCurrentStepHidden:
              !this.state.chosenSectionTileId &&
              this.state.currentStep?.id &&
              utils.isStepHidden(
                this.props.challengeData?.challenge,
                getFlatStepsList({
                  listParticipantSections: this.props.listParticipantSections,
                  participantSteps: this.props.participantSteps,
                }),
                this.state.currentStep,
              ),
            goToCurrentStep: () => {
              this.setStep(
                utils.getFirstAvailableStep(getFlatStepsList(this.props)),
              );
            },
            pageRef: this.props.pageRef,
          }}
        >
          <div className={classes.wrapper} ref={this.wrapperRef}>
            <div
              style={{
                top: stickyPositionTop,
              }}
              className={`${classes.column} ${classes.sidebar}`}
            >
              {this.renderSidebarContent()}
            </div>
            <div
              style={{
                width: cssPBEnabled
                  ? 'calc(var(--opgSidebarLayoutSpace) * 1px)'
                  : `${this.props.settings.sidebarLayoutSpace}px`,
              }}
            />
            <div className={`${classes.column} ${classes.contentBody}`}>
              {this.renderBodyContent()}
            </div>

            {this.renderShareModal()}
            {this.renderQuestionnaire()}
          </div>
        </SidebarLayoutBaseContext.Provider>
      </>
    );
  }

  renderSidebarContent() {
    const {
      challengeData: { challenge },
      listParticipantSections,
    } = this.props;
    const { currentStep, chosenSectionTileId } = this.state;

    const isSPC = isSelfPaced(challenge);
    const isSections = isSPC && listParticipantSections?.length;
    return (
      <>
        <div className={classes.summary}>
          <ChallengeSummary />
        </div>
        <div className={classes.stepsListBox}>
          <OverviewSidebar
            onStepChosen={this.onStepChosen}
            isActive={!chosenSectionTileId && !currentStep}
          />

          {isSections ? this.renderSectionsList() : this.renderStepsList()}
        </div>
      </>
    );
  }

  renderBodyContent() {
    const {
      t,
      challengeData: { challenge },
      isParticipantInSuspendedState,
    } = this.props;
    const {
      chosenSectionTileId,
      currentStep,
      isParticipantCompletedChallengeState,
    } = this.state;
    const isSectionActive = !!chosenSectionTileId;
    const isStepActive = !!currentStep?.id;
    const isOverviewActive = !isSectionActive && !isStepActive;

    const flatStepsList = getFlatStepsList(this.props);
    const { isProgramNotStarted, isProgramFinished } =
      getProgramProgress(flatStepsList);
    const { formId, isQuiz } = this.getQuizData();

    return (
      <>
        <div
          className={`${classes.stepView} ${
            isParticipantInSuspendedState ? classes.stepViewCentered : ''
          }`}
        >
          <div className={classes.stepViewContent} ref={this.stepViewRef}>
            {/* eslint-disable-next-line no-nested-ternary */}
            {isSectionActive ? (
              isParticipantInSuspendedState ? (
                <ProgramRejoin
                  type="SidebarGeneral"
                  prefix={this.props.isMobile ? this.renderBreadcrumbs() : null}
                />
              ) : (
                <SidebarSectionView
                  pageRef={this.props.pageRef}
                  goToCurrentStep={() => {
                    const step = getFirstAvailableStepFromSection(
                      this.props.listParticipantSections,
                    );

                    if (step) {
                      this.setState({
                        currentStep: step,
                        chosenSectionTileId: null,
                      });
                    }
                  }}
                  currentSectionId={this.state.chosenSectionTileId}
                  goToMobileList={this.goToMobileList}
                />
              )
            ) : // eslint-disable-next-line no-nested-ternary
            (isStepActive &&
                this.props.shownTab !== LayoutSelectedTab.Overview) ||
              !this.props.settings.overviewTabDisplay ? (
              isParticipantInSuspendedState ? (
                <ProgramRejoin
                  type="SidebarGeneral"
                  prefix={this.props.isMobile ? this.renderBreadcrumbs() : null}
                />
              ) : (
                this.renderStepView()
              )
            ) : (
              <>
                <ChallengeOverview
                  challenge={challenge}
                  prefix={this.renderBreadcrumbs()}
                  pageRef={this.props.pageRef}
                />
                {isParticipantInSuspendedState ? (
                  <ProgramRejoin type="SidebarOverview" />
                ) : null}
              </>
            )}
          </div>
        </div>
        {isOverviewActive &&
        !isProgramFinished &&
        !isParticipantInSuspendedState &&
        !isQuiz ? (
          <SidebarControlsContainer>
            <SidebarLayoutButton
              disabled={this.getIsStartDisabled()}
              onClick={() => {
                if (isProgramNotStarted) {
                  const isSectionOpened = this.openFirstSectionIfAvailable();
                  if (isSectionOpened) {
                    return;
                  }
                }
                const nextStepToComplete = getNextStepToComplete(flatStepsList);
                if (nextStepToComplete) {
                  this.setStep(nextStepToComplete, true);
                  this.scrollToContent();
                } else {
                  this.openFirstSectionIfAvailable();
                }

                this.sendBiButtonClick(ButtonNames.OverviewStartButton);
              }}
            >
              {isProgramNotStarted
                ? t('challenge.page.overview.start-btn')
                : t('challenge.page.overview.current-step')}
            </SidebarLayoutButton>
          </SidebarControlsContainer>
        ) : null}
        {(isSectionActive ||
          isStepActive ||
          isParticipantCompletedChallengeState) &&
        !isParticipantInSuspendedState &&
        !isQuiz ? (
          <>
            {isParticipantCompletedChallengeState ? (
              <FinishedNotification />
            ) : null}
            {isSectionActive && !isParticipantCompletedChallengeState ? (
              <SectionControls
                currentSectionId={this.state.chosenSectionTileId}
                steps={this.props.participantSteps}
                sections={this.props.listParticipantSections}
                onNextEntity={(entity) => this.onNextEntity(entity)}
                onStepChosen={(step) => this.onStepChosen(step)}
              />
            ) : null}
            {isStepActive && !isParticipantCompletedChallengeState ? (
              <StepControls
                completeBtnDataHook={sidebarLayoutDataHooks.stepCompleteBtn()}
                nextBtnDataHook={sidebarLayoutDataHooks.stepNextBtn()}
                currentStep={this.state.currentStep}
                isCompletedChallenge={
                  this.state.isParticipantCompletedChallengeState
                }
                steps={this.props.participantSteps}
                sections={this.props.listParticipantSections}
                buttonState={this.props.buttonState}
                onStepResolve={(step) => this.onStepResolve(step)}
                onNextEntity={(entity) => this.onNextEntity(entity)}
                onFeedbackView={(step) => this.onFeedbackView(step)}
                ShareButton={() => this.renderShareButton()}
              />
            ) : null}
          </>
        ) : null}
        {isQuiz ? (
          <FormQuizControls
            formId={formId}
            onNextEntity={(entity) => this.onNextEntity(entity)}
            ShareButton={() => this.renderShareButton()}
          />
        ) : null}
      </>
    );
  }

  renderSectionsList() {
    const { chosenSectionTileId, currentStep } = this.state;

    return (
      <>
        <SectionsListAsTiles
          dataHook={sidebarLayoutDataHooks.sectionsListTiles()}
          dataHookForStepsList={sidebarLayoutDataHooks.stepsListTiles()}
          chosenSectionTileId={chosenSectionTileId}
          currentStepId={currentStep?.id}
          onStepChosen={this.onStepChosen}
          onSectionChosen={this.onSectionChosen}
        />
      </>
    );
  }

  renderStepsList() {
    const {
      challengeData: { challenge },
      participantSteps: { steps } = { steps: [] },
      isParticipantStepsLoading,
    } = this.props;
    const { currentStep } = this.state;
    if (isParticipantStepsLoading) {
      return null;
    }

    return (
      <StepsListAsTiles
        steps={steps}
        isSPC={isSelfPaced(challenge)}
        currentStepId={currentStep?.id}
        onStepChosen={this.onStepChosen}
      />
    );
  }

  renderBreadcrumbs() {
    const { listParticipantSections } = this.props;
    const { currentStep } = this.state;

    return (
      <StepViewBreadcrumbs
        sections={listParticipantSections}
        currentStep={currentStep}
        goToMobileList={this.goToMobileList}
        onSectionChosen={this.onSectionChosen}
      />
    );
  }

  renderStepView() {
    const {
      challengeData: { challenge },
      isListParticipantSectionsRequestInProgress,
      listParticipantSections,
      isParticipantStepsLoading,
      participantSteps,
    } = this.props;
    const quizData = this.getQuizData();

    return (
      <div data-hook={sidebarLayoutDataHooks.stepViewWrapper()}>
        {quizData.isQuiz ? (
          <FormQuiz
            key={`form-quiz-${quizData.formId}`}
            formId={quizData.formId}
            submissionId={quizData.quizSubmissionId}
            onResolve={(lastSubmissionId: string) => {
              void this.resolveQuiz(lastSubmissionId);
            }}
            stepName={
              this.state.currentStep?.source?.settings?.general?.description
                ?.title
            }
            Breadcrumbs={() => this.renderBreadcrumbs()}
            ref={this.formQuizRef}
            StepShareButton={() => this.renderShareButton()}
          />
        ) : (
          <SideBarStepView
            dataHook={sidebarLayoutDataHooks.stepView()}
            participantSteps={participantSteps}
            listParticipantSections={listParticipantSections}
            challenge={challenge}
            isListParticipantSectionsRequestInProgress={
              isListParticipantSectionsRequestInProgress
            }
            goToCurrentStep={() => {
              this.setStep(
                utils.getFirstAvailableStep(getFlatStepsList(this.props)),
              );
            }}
            isParticipantStepsLoading={isParticipantStepsLoading}
            currentStep={this.state.currentStep}
            Breadcrumbs={() => this.renderBreadcrumbs()}
            StepShareButton={() => this.renderShareButton()}
            pageRef={this.props.pageRef}
          />
        )}
      </div>
    );
  }

  renderShareButton() {
    const {
      t,
      location: { url } = { url: '' },
      isResolveStepRequestInProgress,
      settings,
    } = this.props;
    const isResolved = isStepResolved(this.state.currentStep);
    const cssPBEnabled = this.props.experiments.enabled(
      'specs.programs.OOIStyleBP',
    );
    const sharingForParticipantIsEnabled =
      cssPBEnabled || settings.socialSharingForParticipantIsEnabled;
    return isResolved && sharingForParticipantIsEnabled ? (
      <ShareButton
        className={classes.shareButton}
        shareData={{
          title: t('social-share.title'),
          url,
        }}
        onClick={(sharePromise) => {
          if (!sharePromise && !isResolveStepRequestInProgress) {
            this.setState(
              {
                isShareModalOpened: true,
              },
              () => {
                this.sendBiButtonClick(ButtonNames.ShareCompletedStep);
              },
            );
          }
        }}
        withIcon
      />
    ) : null;
  }

  renderShareModal() {
    const { currentStep, isShareModalOpened } = this.state;

    return currentStep ? (
      <ShareModal
        step={currentStep}
        isOpened={isShareModalOpened}
        onClose={() => {
          this.setState({
            isShareModalOpened: false,
          });
        }}
      />
    ) : null;
  }

  renderQuestionnaire() {
    const { currentStep, isFeedbackModalOpened, savedQuestionnaireData } =
      this.state;

    return currentStep ? (
      <>
        <ChallengeQuestionnaire
          isOpened={isFeedbackModalOpened}
          currentStep={currentStep}
          savedQuestionnaireData={savedQuestionnaireData}
          onSaveIntermediateData={(type, data) => {
            this.setState({
              savedQuestionnaireData: {
                ...savedQuestionnaireData,
                ...{
                  [type]: data || {},
                },
              },
            });
          }}
          onResolve={() => {
            void this.resolveStep();
          }}
          onCancel={() => {
            this.setState({
              isFeedbackModalOpened: false,
              savedQuestionnaireData: null,
            });
          }}
        />
      </>
    ) : null;
  }
}

export default withExperiments<any>(
  withBi(withGeneralData(withToast(withLeaveChallengeModal(SidebarComponent)))),
);
